































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/js/api";
import { ImagePreview } from "vant";

@Component({
  filters: {
    // 时间戳转字符串
    formatDate(item: Dictionary<any>): string {
      let value = item.value;
      let date_type = item.date_type;
      let type = "Y-m-d";
      switch (date_type) {
        case "date":
          type = "Y-m-d";
          break;
        case "time":
          type = "Y-m-d H:i:s";
          break;
        case "year-month":
          type = "Y-m";
          break;
        case "datetime":
          type = "Y-m-d H:i";
          break;
      }
      return api.date(type, value);
    },
    // 时间格式转时间戳
    dateType(value: string): Date {
      if (value) {
        return new Date(Number(value));
      } else {
        return new Date();
      }
    },
    // 对象转换成数组
    toArray(obj: Dictionary<string>): string[] {
      let arr: string[] = [];
      for (let k in obj) {
        if (k === "") {
          arr.unshift(obj[k]);
        } else {
          arr.push(obj[k]);
        }
      }
      return arr;
    }
  }
})
export default class MyForm extends Vue {
  public value = "";
  public showPopup: Dictionary<string> = {}; // 控制所有需要弹框的组件（picker）
  public error: Dictionary<string> = {}; // 所有错误消息
  public region: Dictionary<MyForm.region> = {};
  public region2: Dictionary<MyForm.region> = {};
  public checkBoxList: Dictionary<string[]> = {}; // 所有复选框的临时存储值
  public fileList: Dictionary<any[]> = {}; // 所有图片上传的临时储存值
  public showKeyboard: boolean = false; // 数字键盘框（密码框时用到）

  @Prop() private form_list!: MyForm.formList[]; // 初始化列表数据
  @Prop() private hide_required_icon?: boolean; // 是否隐藏表单必填星号（不响验证功能，只影响显示）

  emitData(index: number) {
    let name = this.form_list[index].name;
    if (this.error[name]) {
      this.Validate(index);
    }
    this.$emit("Change", this.form_list);
  }
  /* **************************** input *********************************** */
  // input框值改变
  inputChange(index: number, value: string) {
    this.$set(this.form_list[index], "value", value);
    this.emitData(index);
  }
  /* **************************** picker *********************************** */
  // picker单选点确认
  onConfirm(index: number, value: string) {
    let name = this.form_list[index].name;
    let range = this.form_list[index].range;
    let val = "";
    for (let k in range) {
      if (range[k] === value) {
        val = k;
      }
    }
    this.$set(this.showPopup, name, false);
    this.$set(this.form_list[index], "value", val);
    this.emitData(index);
  }
  /* **************************** datePicker *********************************** */
  // datePicker单选点确认
  onConfirmDate(index: number, value: Date) {
    let val = String(value.getTime());
    let name = this.form_list[index].name;
    this.$set(this.showPopup, name, false);
    this.$set(this.form_list[index], "value", val);
    this.emitData(index);
  }
  formatter(type, value) {
    switch (type) {
      case "year":
        return `${value}年`;
      case "month":
        return `${value}月`;
      case "day":
        return `${value}日`;
      case "hour":
        return `${value}时`;
      case "minute":
        return `${value}分`;
      case "second":
        return `${value}秒`;
    }
    return value;
  }
  /* **************************** regionPicker *********************************** */
  // 获取地区列表
  getRegion(
    item: MyForm.formList,
    picker?: any,
    values?: object[],
    index: number = 0,
    region_id: number = 1
  ) {
    let level = item.region_level || 4;
    let name = item.name;
    let region = this.region[name];
    if (index >= level) {
      return;
    }
    let _this = this;
    this.$set(region, "loading", true);
    this.$api.request({
      url: "common/region/region-all",
      loading: "",
      data: { region_id },
      success(res) {
        let data = res.data;
        if (picker) {
          picker.setColumnValues(index, data);
        } else {
          _this.$set(region.columns[index], "values", data);
        }
        // 默认没有值就选中第一个
        let select_value = data[0];
        // 筛选出是否存在默认值
        switch (index) {
          case 0:
            if (!region.prov) {
              break;
            }
            data.forEach((item: any) => {
              if (String(item.region_id) === region.prov) {
                select_value = item;
                _this.$set(region.value, 0, item);
              }
            });
            _this.$set(region, "prov", "");
            break;
          case 1:
            if (!region.city) {
              break;
            }
            data.forEach((item: any) => {
              if (String(item.region_id) === region.city) {
                select_value = item;
                _this.$set(region.value, 1, item);
              }
            });
            _this.$set(region, "city", "");
            break;
          case 2:
            if (!region.area) {
              break;
            }
            data.forEach((item: any) => {
              if (String(item.region_id) === region.area) {
                select_value = item;
                _this.$set(region.value, 2, item);
              }
            });
            _this.$set(region, "area", "");
            break;
          case 3:
            if (!region.town) {
              break;
            }
            data.forEach((item: any) => {
              if (String(item.region_id) === region.town) {
                select_value = item;
                _this.$set(region.value, 3, item);
              }
            });
            _this.$set(region, "town", "");
            break;
        }
        _this.$set(region.select_value, index, select_value);
        _this.$set(region, "loading", false);
        if (index < level - 1) {
          _this.getRegion(
            item,
            picker,
            values,
            index + 1,
            select_value.region_id
          );
        }
      }
    });
  }
  // 通过region_id获取地区名
  getRegionName(item: MyForm.formList): string {
    if (!this.region[item.name] || !item.value) {
      return "";
    }
    let value = this.region[item.name].value;
    let val = "";
    value.map((item, index) => {
      if (index !== 0) {
        val += " , ";
      }
      val += item.region_name;
    });
    return val;
  }
  // pickerRegion值改变
  onChangeRegion(
    picker: any,
    values: MyForm.regionValue[],
    idx: number,
    index: number
  ) {
    // idx: 0：省改变，1：市改变，2：区改变，3：镇改变
    let item = this.form_list[index];
    let region_id = values[idx].region_id;
    this.region[item.name].select_value = values;

    this.getRegion(item, picker, values, idx + 1, region_id);
  }
  // pickerRegion点确认
  onConfirmRegion(index: number, value: MyForm.regionValue[]) {
    let name = this.form_list[index].name;
    let level = this.form_list[index].region_level || 4;
    this.$set(this.showPopup, name, false);
    this.$set(this.form_list[index], "value", value[level - 1].region_id);
    this.$set(this.region[name], "value", value);
    this.emitData(index);
  }
  /* **************************** regionPicker2 *********************************** */
  /* **************************** 复选框 *********************************** */
  checkToggle(index: number, name: string) {
    let refs: any = this.$refs[name];
    refs[index].toggle();
  }
  onConfirmCheck(index: number) {
    let name = this.form_list[index].name;
    let range = this.form_list[index].range;
    let value = this.checkBoxList[name];
    let data: string[] = [];
    for (let k in range) {
      if (value.indexOf(range[k]) !== -1) {
        data.push(k);
      }
    }
    this.$set(this.showPopup, name, false);
    this.$set(this.form_list[index], "checked", value);
    this.$set(this.form_list[index], "value", data.join(","));
    this.emitData(index);
  }
  /* **************************** 图片上传 *********************************** */
  // 上传图片后执行
  afterRead(index: number, file: any, name: string) {
    // this.$toast('res');
    let item = this.form_list[index];
    let value = item.value;
    let value_json = value === "" ? [] : value.split(",");
    console.log(file, "555555555555555");
    let file_list = file;
    // file不是对象
    if (!(file instanceof Array)) {
      file_list = [file];
    }
    file_list.forEach((it: any, idx: number) => {
      let i = this.fileList[name].length - file_list.length + idx;
      // 上传图片
      
      this.$api.uploadFile(
        it.file,
        res => {
          this.$set(this.fileList[name][i], "content", res.data.url);
          value_json.push(res.data.url);
          this.$set(item, "value", value_json.join(","));
          this.emitData(index);
          // this.$toast(res);
        },
        res => {
          this.$set(this.fileList[name][i], "content", "");
          this.$set(this.fileList[name][i], "status", "failed");
          this.$set(this.fileList[name][i], "message", "上传失败");
          value_json.push("");
          this.$set(item, "value", value_json.join(","));
          this.emitData(index);
          // this.$toast(res);
        }
      );
    });
  }
  // 删除图片后执行
  deleteImage(index: number, file: any, detail: any) {
    let item = this.form_list[index];
    let value = item.value;
    let value_json = value === "" ? [] : value.split(",");
    value_json.splice(detail.index, 1);
    this.$set(item, "value", value_json.join(","));
    return true;
  }
  /* **************************** image_disabled *********************************** */
  // 预览图片
  ImagePreview(index, images) {
    ImagePreview({ images: images.split(","), startPosition: index });
  }
  /* **************************** password *********************************** */
  onPasswordInput(index: number, key: number) {
    let item = this.form_list[index];
    this.$set(this.form_list[index], "value", (item.value + key).slice(0, 6));
    this.emitData(index);
    if (item.validate_max === item.value.length) {
      this.showKeyboard = false;
    }
  }
  onPasswordDelete(index: number) {
    let item = this.form_list[index];
    if (item.value.length === 0) {
      return;
    }
    this.$set(
      this.form_list[index],
      "value",
      item.value.slice(0, item.value.length - 1)
    );
    this.emitData(index);
  }
  /* **************************** idCard *********************************** */
  onIdCardInput(index: number, key: number) {
    let item = this.form_list[index];
    this.$set(this.form_list[index], "value", item.value + key);
    this.emitData(index);
  }
  onIdCardDelete(index: number) {
    let item = this.form_list[index];
    if (item.value.length === 0) {
      return;
    }
    this.$set(
      this.form_list[index],
      "value",
      item.value.slice(0, item.value.length - 1)
    );
    this.emitData(index);
  }
  /* **************************** 验证 *********************************** */
  // 验证数据
  Validate(index: number) {
    let value = this.form_list[index].value;
    let validate = this.form_list[index].validate;
    let validate_min = this.form_list[index].validate_min;
    let validate_max = this.form_list[index].validate_max;
    let required = this.form_list[index].required;
    let hidden = this.form_list[index].hidden;
    let name = this.form_list[index].name;
    let type = this.form_list[index].type;
    let label = this.removeSpace(this.form_list[index].label);

    if (hidden) {
      return this.$set(this.error, name, null);
    }
    if (required && !value) {
      let action_text = "输入";
      if (type === "picker" || type === "regionPicker" || type === "checkbox") {
        action_text = "选择";
      } else if (type === "image") {
        action_text = "上传";
      }
      return this.$set(this.error, name, "请" + action_text + label);
    } else if (required && value) {
      this.$set(this.error, name, null);
    } else if (!value) {
      return this.$set(this.error, name, null);
    }
    switch (validate) {
      case "idCard":
        if (!this.$api.ValidateIdCard(value)) {
          this.$set(this.error, name, label + "格式错误");
        } else {
          this.$set(this.error, name, null);
        }
        break;
      case "phone":
        if (!this.$api.ValidatePhone(value)) {
          this.$set(this.error, name, label + "格式错误");
        } else {
          this.$set(this.error, name, null);
        }
        break;
      default:
        break;
    }

    if (validate_min && value && type === "image") {
      // 图片的最小张数验证
      if (value.split(",").length < validate_min) {
        this.$set(this.error, name, label + "需大于" + validate_min + "张");
      }
    } else if (validate_min && validate_max && value) {
      if (value.length < validate_min || value.length > validate_max) {
        let error_val =
          label + "需小于" + validate_max + "位且大于" + validate_min + "位";
        if (validate_min === validate_max) {
          error_val = label + "长度为" + validate_max + "位";
        }
        this.$set(this.error, name, error_val);
      }
    } else if (validate_min && value) {
      if (value.length < validate_min) {
        this.$set(this.error, name, label + "需大于" + validate_min + "位");
      }
    } else if (validate_max && value) {
      if (value.length > validate_max) {
        this.$set(this.error, name, label + "需小于" + validate_max + "位");
      }
    }
  }
  // 去除所有空格
  removeSpace(value: string) {
    return value.replace(/\s+/g, "");
  }
  // 验证所有数据
  ValidateAll(): boolean {
    let boolean = true;
    this.form_list.forEach((item, index) => {
      this.Validate(index);
    });
    for (let k in this.error) {
      if (this.error[k]) {
        boolean = false;
      }
    }
    return boolean;
  }

  onload() {
    console.log("组件初始化");
    // 确认所有的picker，所有error，生成对应的数组
    this.form_list.forEach((item, index) => {
      this.$set(this.error, item.name, null);
      if (
        item.type === "picker" ||
        item.type === "datePicker" ||
        item.type === "idCard"
      ) {
        this.$set(this.showPopup, item.name, false);
      }
      if (item.type === "checkbox") {
        let checked: string[] = [];
        if (item.value) {
          let list = item.value.split(",");
          for (let val of list) {
            if (item.range && item.range[val]) {
              checked.push(item.range[val]);
            }
          }
        }
        this.$set(this.checkBoxList, item.name, checked);
        this.$set(this.form_list[index], "checked", checked);
        this.$set(this.showPopup, item.name, false);
      }
      if (item.type === "regionPicker") {
        this.$set(this.showPopup, item.name, false);
        let columns: any[] = [];
        for (let i = 0; i < (item.region_level || 4); i++) {
          columns.push({ values: [] });
        }
        this.$set(this.region, item.name, {
          loading: false,
          select_value: [],
          value: [],
          columns,
          prov: item.prov,
          city: item.city,
          area: item.area,
          town: item.town
        });
        this.getRegion(item);
      }
      if (item.type === "regionPicker2") {
        this.$set(this.showPopup, item.name, false);
        let columns: any[] = [];
        for (let i = 0; i < (item.region_level || 4); i++) {
          columns.push({ values: [] });
        }
        this.$set(this.region2, item.name, {
          loading: false,
          select_value: [],
          select_index: 0,
          value: [],
          columns
        });
        this.$set(this.region2[item.name].columns[0], "values", [1, 2, 3]);

        // this.getRegion(item);
      }
      if (item.type === "image") {
        console.log(item.value,88888888)
        var srcList = item.value?item.value.split(","):[];
        var name = item.value?item.name:'';
        if(window.location.href.search('pay')!=-1){//如果是付款页面，清空缓存
          this.$set(this.fileList, item.name, []);
        }
        if(window.location.href.search('shop/apply')!=-1){//如果是店铺页面，将图片数据渲染到组件
          if (srcList.length == 1) {
            console.log('456')
            this.$set(this.fileList, item.name, [{ url: item.value }]);
          } else if (srcList.length > 1) { 
            console.log('123')
            srcList.map((ite, ind) => {
              this.fileList.door_decs[ind] = { url: ite };
            });
          }
        }
        console.log(this.fileList,555555555)
        
      }
    });
  }
  mounted() {
    console.log("组件挂载");
    this.onload();
  }
}
